// http(发布接口)
import request from '../http'

export default class Infos {
    /**
 * 编辑发帖
 * @param {*} params
 * @returns
 */
    static updateArticleCard(data) {
        return request.post('/api/v1/articlecurrency/updateArticleCard', data)
    }
    /**
* 发帖
* @param {*} params
* @returns
*/
    static addArticleCard(data) {
        return request.post('/api/v1/articlecurrency/addArticleCard', data)
    }
    /**
* 评价帖子
* @param {*} params
* @returns
*/
    static articleEvl(data) {
        return request.post('/api/v1.articlecurrency/articleEvl',data)
    }
}