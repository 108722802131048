<!-- 回帖 -->
<template>
  <div class="_postMessage">
    <div class="moban_tou flex align-items">
      <div class="fenlei" v-for="(item, index) in liebiao" :key="index">
        {{ item }}
        <div class="sanjiao"></div>
        <div class="act" v-if="timuNum == index">{{ liebiao[index] }}</div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="w-full biao">
      <!-- 表头 -->
      <div class="biaoshen">
        <!-- 模板 -->
        <div
          class="w-full flex hang"
          v-for="(item, index) in list"
          :key="index"
          @click="yonghu(item.cardsaltpid)"
        >
          <!-- 标题 -->
          <div class="btt">
            <span class="zixun">
              <span class="zi" v-if="item.type_txt == '咨询'">
                【{{ "咨询" }}】
              </span>
              <span class="hong" v-else-if="item.type_txt == '投诉'">
                【{{ "投诉" }}】
              </span>
              <span class="lv" v-else-if="item.type_txt == '建议'">
                【{{ "建议" }}】
              </span>
              <span class="fontEight">
                【
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="item.department_name"
                  placement="bottom"
                  :disabled="gf(item.department_name)"
                >
                  <span> {{ buchandu(item.department_name) }}</span>
                </el-tooltip>
                】
              </span>
            </span>
            <span class="fontSeven">{{ item.articletitle }}</span>
          </div>
          <!-- 回复内容 -->
          <div class="huifn">
            <div class="fdd flex lan">
              <div class="white-space">{{ "回复" }}</div>
              <div class="white-space">{{ item.usehdname }}:</div>
            </div>
            <div class="kuagao">
              {{ kongge(item.contentstext) }}
              <span
              v-if="item.is_shanchu != 1"
                class="sdel"
                @click.stop="del(item.id, index, item.is_shanchu)"
                >删除</span
              >
            </div>
          </div>
          <!-- 帖子主人 -->
          <div class="flex zhren">
            <div class="fontSix">{{ item.usename }}</div>
            <div class="content-time">{{ item.cmtime }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/geren";
export default {
  data() {
    return {
      liebiao: ["我的回帖"],
      timuNum: 0,
      page: 1,
      pageLine: 10,
      list: [],
      loading: null,
    };
  },
  mounted() {
    this.useCommentreply();
  },
  methods: {
    // 删除评论
    del(id, index, deinum) {
      if (deinum == 1) {
        this.$message({
          message: "该评论已申请删除,请等待管理员审核!",
          type: "warning",
        });
        return;
      }
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.1)",
      });
      let that = this;
      this.$confirm("是否删除该条评论", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await API.delcomment({
            cardid: id,
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
                that.useCommentreply();
              } else {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
            })
            .finally((suo) => {
              // 都执行
              this.loading.close();
            });
        })
        .catch(() => {});
    },
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length > 2) {
        return false;
      } else {
        return true;
      }
    },
    // 部门长度
    buchandu(str) {
      let a = str.split(",");
      if (a.length <= 2) {
        return a.toString();
      } else {
        return a[0] + "," + a[1] + "...";
      }
    },
    // 去除nbsp
    kongge(str) {
      if (str == null) {
        return "";
      }
      let words = str.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, ""); //这里是去除标签
      return words.replace(/\s/g, ""); //这里是去除空格
    },
    async useCommentreply() {
      let res = await API.userReplylist({
        page: this.page,
        pageLine: this.pageLine,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.$parent.totals(res.data.total);
        }
      });
    },
    pages(num) {
      this.page = num;
      this.useCommentreply();
    },
    zongye(num) {
      this.pageLine = num;
      this.useCommentreply();
    },
    // 其他用户
    async yonghu(id) {
      let a = true;
      let res = await API.articleInfo({
        cardid: id,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          a = false;
        }
      });
      if (a == false) {
        return;
      }
      this.$store.state.loginMessage.cardsalt = id;
      this.jump("details", this.$store.state.loginMessage);
    },
  },
};
</script>

<style scoped lang="scss">
._postMessage {
  width: 100%;
}

.moban_tou {
  height: 46px;

  .fenlei {
    height: 100%;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0 29px 0 37px;
    line-height: 46px;
    position: relative;
    cursor: pointer;

    .sanjiao {
      position: absolute;
      bottom: 0;
      width: 10px;
      height: 5px;
      left: calc(50% - 5px);
      background: transparent;
      border-style: solid;
      border-width: 0 10px 5px 10px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.biao {
  .one {
    flex: 1.2;
  }

  .two {
    flex: 0.8;
  }

  .three {
    flex: 3;
  }

  .four {
    flex: 1;
    text-align: center;
  }

  .five {
    flex: 1;
    text-align: center;
  }

  .six {
    flex: 1;
    text-align: center;
  }

  .bioatou {
    width: 100%;

    background: #e8effa;
    align-items: center;
    padding: 0 13px 0 27px;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #0653ac;
    letter-spacing: 0;
    font-weight: 400;
  }

  .biaoshen {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;

    .hang:nth-child(even) {
      background: #f6f6f6;
    }

    .hang {
      padding: 10px 13px 10px 27px;
      min-height: 80px;
      cursor: pointer;
      flex-direction: column;
      justify-content: center;
      border-bottom: 1px dashed transparent;
      background: linear-gradient(#fff, #fff) padding-box,
        repeating-linear-gradient(
          -45deg,
          #d6dbe3 0,
          #d6dbe3 0.3em,
          #fff 0,
          #fff 0.6em
        );
      /* 0.3em设置虚线点的宽度或长度；0.6em设置虚线点的间距 */
    }
  }

  .qitren {
    width: 42px;
    height: 42px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 10px 0 0;
  }

  .zixun {
    font-size: 18px;

    .zi {
      color: #2b317f;
    }

    .lv {
      color: #00a576;
    }

    .hong {
      color: #ff5637;
    }
  }

  .sta {
    .hong {
      color: #c90e0e;
    }

    .cheng {
      color: #fb8a2b;
    }

    .lv {
      color: #04af23;
    }
  }
}

.center {
  width: 100%;
  min-height: 49px;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 8px;
  padding: 0 0 0 10px;
}

.act {
  position: absolute;
  left: 0;
  top: calc(50% - 16px);
  background: #3eacdd !important;
  font-size: 15px !important;
  color: #ffffff !important;
  height: 70%;
  width: 100%;
  line-height: 32px;
  text-align: center;
}

.fontSix {
  font-family: PingFangSC-Regular;
  font-size: 12px;
  color: #484c51;
  letter-spacing: 0;
  font-weight: 400;
  margin-right: 36px;
}

.fontSeven {
  overflow: hidden;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #18191a;
  letter-spacing: 0;
  font-weight: 500;
}

.fontEight {
  font-family: PingFangSC-Regular;
  font-size: 18px;
  color: #484c51;
  letter-spacing: 0;
  font-weight: 400;
}

.content-time {
  font-family: PingFangSC-Regular;
  font-size: 13px;
  color: #7b8391;
  letter-spacing: 0;
  font-weight: 400;
}

.zhren {
  padding: 0 0 0 10px;
  margin: 10px 0 0 0;
  box-sizing: border-box;
}

.fdd {
  float: left;
  line-height: 28px;
}

.huifn {
  padding: 0 0 0 8px;
  box-sizing: border-box;
}

.lan {
  color: #005293;
}

.btt {
  margin-bottom: 10px;
}

.kuagao {
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #191a1c;
  letter-spacing: 0;
  font-weight: 400;
  line-height: 28px;
}
.sdel {
  margin-left: 10px;
  cursor: pointer;
  font-weight: 700;
  color: #0653ac;
}
.sdel:hover {
  color: #df2744;
  
}
</style>