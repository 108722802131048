<!-- 我的收藏 -->
<template>
  <div class="_postMessage">
    <div class="moban_tou flex align-items">
      <div class="fenlei" v-for="(item, index) in liebiao" :key="index">
        {{ item }}
        <div class="sanjiao"></div>
        <div class="act" v-if="timuNum == index">{{ liebiao[index] }}</div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="w-full biao">
      <!-- 表头 -->
      <div class="bioatou flex">
        <div class="one">{{ "编号" }}</div>
        <div class="two">{{ "诉求类型" }}</div>
        <div class="three">{{ "标题" }}</div>
        <div class="four">{{ "收藏时间" }}</div>
        <div class="five">{{ "受理单位" }}</div>
        <div class="six">{{ "操作" }}</div>
      </div>
      <div class="biaoshen">
        <!-- 模板 -->
        <div
          class="w-full flex hang align-items"
          v-for="(item, index) in list"
          :key="index"
          @click="xiangqing(item.cardsaltpid)"
        >
          <div class="one">{{ item.cardsaltpid }}</div>
          <div class="two zixun">
            <div class="zi" v-if="item.type_txt == '咨询'">
              【{{ "咨询" }}】
            </div>
            <div class="hong" v-else-if="item.type_txt == '投诉'">
              【{{ "投诉" }}】
            </div>
            <div class="lv" v-else>【{{ "建议" }}】</div>
          </div>
          <div class="three">{{ item.title }}</div>
          <div class="four">{{ item.handtime.slice(0, 10) }}</div>
          <div class="five">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.unit_name"
              placement="top-start"
              :disabled="gf(item.unit_name)"
            >
              <div>{{ buchang(item.unit_name) }}</div>
            </el-tooltip>
            <!-- {{ item.unit_name }} -->
          </div>
          <div class="six sta lv" @click.stop="shouchang(item.cardsaltpid)">
            {{ "取消收藏" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/geren";
export default {
  data() {
    return {
      liebiao: ["全部"],
      timuNum: 0,
      page: 1,
      pageLine: 10,
      list: [],
      loading: null,
    };
  },
  mounted() {
    this.artileContentContor();
  },
  methods: {
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length > 2) {
        return false;
      } else {
        return true;
      }
    },
    // 部门长度
    buchang(str) {
      let a = str.split(",");
      if (a.length == 1) {
        return a.toString();
      } else {
        return a[0] + "," + "...";
      }
    },
    // 取消收藏
    async shouchang(id) {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await API.artileCollect({
        cardid: id,
        type: 1,
        kind: 0,
        port: 0,
        classify: 0,
      })
        .then((res) => {
          if (res.code == 1) {
            this.artileContentContor();
          }
          this.loading.close();
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    async artileContentContor() {
      let res = await API.artileContentContor({
        kind: 2,
        type: 0,
        page: this.page,
        pageLine: this.pageLine,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.$parent.totals(res.data.total);
        }
      });
    },
    pages(num) {
      this.page = num;
      this.artileContentContor();
    },
    zongye(num) {
      this.pageLine = num;
      this.artileContentContor();
    },
    // 跳转至详情
    async xiangqing(id) {
      let a = true;
      let res = await API.articleInfo({
        cardid: id,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          a = false;
        }
      });
      if (a == false) {
        return;
      }
      this.$store.state.loginMessage.cardsalt = id;
      this.jump("details", this.$store.state.loginMessage);
    },
  },
};
</script>

<style scoped lang="scss">
._postMessage {
  width: 100%;
}

.moban_tou {
  height: 46px;

  .fenlei {
    height: 100%;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0 29px 0 37px;
    line-height: 46px;
    position: relative;
    cursor: pointer;

    .sanjiao {
      position: absolute;
      bottom: 0;
      width: 10px;
      height: 5px;
      left: calc(50% - 5px);
      background: transparent;
      border-style: solid;
      border-width: 0 10px 5px 10px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.biao {
  .one {
    flex: 1.2;
  }

  .two {
    flex: 0.8;
  }

  .three {
    flex: 3;
  }

  .four {
    flex: 1;
    text-align: center;
  }

  .five {
    flex: 1;
    text-align: center;
  }

  .six {
    flex: 1;
    text-align: center;
  }

  .bioatou {
    width: 100%;
    height: 53px;
    background: #e8effa;
    align-items: center;
    padding: 0 13px 0 27px;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #0653ac;
    letter-spacing: 0;
    font-weight: 400;
  }

  .biaoshen {
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;

    .hang:nth-child(even) {
      background: #f6f6f6;
    }

    .hang {
      padding: 0 13px 0 27px;
      min-height: 53px;
      cursor: pointer;
    }
  }

  .zixun {
    .zi {
      color: #2b317f;
    }

    .lv {
      color: #00a576;
    }

    .hong {
      color: #ff5637;
    }
  }

  .sta {
    &:hover {
      color: #c90e0e !important;
    }

    .hong {
      color: #c90e0e;
    }

    .cheng {
      color: #fb8a2b;
    }

    .lv {
      color: #04af23;
    }
  }
}

.act {
  position: absolute;
  left: 0;
  top: calc(50% - 16px);
  background: #3eacdd !important;
  font-size: 15px !important;
  color: #ffffff !important;
  height: 70%;
  width: 100%;
  line-height: 32px;
  text-align: center;
}
</style>