<!-- 我的点赞 -->
<template>
  <div class="dainzan">
    <div class="biaoge">
      <!-- 头部 -->
      <div class="moban_tou flex align-items">
        <div class="fenlei" v-for="(item, index) in liebiao" :key="index">
          {{ item }}
          <div class="sanjiao"></div>
          <div class="act" v-if="timuNum == index">{{ liebiao[index] }}</div>
        </div>
      </div>
      <!-- 表身 -->
      <div class="tab_shen w-full">
        <!-- 模板 -->
        <div
          class="cardd"
          v-for="(item, index) in tieList"
          :key="index"
          :class="{ noneBor: index + 1 == tieList.length }"
          @click="xiangqing(item.cardsalt)"
        >
          <div class="flex">
            <div class="imgT" v-if="item.images.length == 1">
              <img
                :src="item.images[0].di"
                v-if="item.images[0].geshi == '图片'"
                class="w-full h-full"
                alt=""
              />
              <video
                :src="item.images[0].di"
                v-else
                class="w-full h-full"
              ></video>
              <div class="bofang" v-if="item.images[0].geshi == '视频'">
                <img
                  src="../../../assets/img/xinban/bofang.png"
                  class="w-full h-full"
                  alt=""
                />
              </div>
            </div>
            <div class="flex1">
              <div class="flex biaoti align-items">
                <div class="one">
                  <div class="zi" v-if="item.type_txt == '咨询'">
                    【{{ "咨询" }}】
                  </div>
                  <div class="hong" v-else-if="item.type_txt == '投诉'">
                    【{{ "投诉" }}】
                  </div>
                  <div class="lv" v-else-if="item.type_txt == '建议'">
                    【{{ "建议" }}】
                  </div>
                </div>
                <div class="two">
                  【
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="item.unit_name"
                    placement="bottom"
                    :disabled="gf(item.unit_name)"
                  >
                    <span> {{ buchandu(item.unit_name) }}</span>
                  </el-tooltip>
                  <!-- {{ item.unit_name }} -->
                  】
                </div>
                <div class="flex flex1">
                  <!--  -->
                  <div class="three">{{ biaoti(item) }}</div>
                  <div class="four">
                    <img
                      src="../../../assets/img/one/img1.png"
                      v-if="item.status == 0"
                      class="w-full h-full"
                      alt=""
                    />
                    <img
                      src="../../../assets/img/one/img1.png"
                      v-else-if="item.status == 1"
                      class="w-full h-full"
                      alt=""
                    />
                    <img
                      src="../../../assets/img/one/yishouli.png"
                      v-else-if="item.status == 2"
                      class="w-full h-full"
                      alt=""
                    />
                    <img
                      src="../../../assets/img/one/yihuifu.png"
                      v-else-if="item.status == 3"
                      class="w-full h-full"
                      alt=""
                    />
                    <img
                      src="../../../assets/img/one/img2.png"
                      v-else-if="item.status == 4"
                      class="w-full h-full"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="nerong" v-if="item.images.length == 1"></div>
              <div class="nerong" v-if="item.images.length > 1">
                <div
                  class="imgT"
                  v-for="(item, index) in item.images"
                  :key="index"
                >
                  <img
                    :src="item.di"
                    v-if="item.geshi == '图片'"
                    class="w-full"
                    alt=""
                  />
                  <video :src="item.di" v-else class="w-full h-full"></video>
                  <div class="bofang" v-if="item.geshi == '视频'">
                    <img
                      src="../../../assets/img/xinban/bofang.png"
                      class="w-full"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <!-- 作者点赞 -->
              <div class="zuozhe flex w-full space-between align-items">
                <div class="flex">
                  <div class="up">{{ item.name }}</div>
                  <div>{{ item.articletime }}</div>
                </div>
                <div class="flex">
                  <div class="flex ml23 zan" @click.stop="zan(item.cardsalt)">
                    <div>{{ "取消点赞" }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/geren";
export default {
  data() {
    return {
      tieList: [], //帖子列表
      onePai: 0, //二级排序
      loading: null, //等待效果
      page: 1,
      zongyes: 10,
      dangqian: 0, //一级排序
      liebiao: ["全部"],
      timuNum: 0,
      loading: null,
    };
  },
  mounted() {
    this.articleCardList();
  },
  methods: {
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length > 2) {
        return false;
      } else {
        return true;
      }
    },
    // 部门长度
    buchandu(str) {
      let a = str.split(",");
      if (a.length <= 2) {
        return a.toString();
      } else {
        return a[0] + "," + a[1] + "...";
      }
    },
    // 取消点赞
    async zan(id) {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await API.artileCollect({
        cardid: id,
        type: 0,
        kind: 0,
        port: 0,
        classify: 0,
      })
        .then((res) => {
          if (res.code == 1) {
            this.articleCardList();
          }
          this.loading.close();
        })
        .finally((suo) => {
          // 都执行
          this.loading.close();
        });
    },
    // 跳转至详情
    async xiangqing(id) {
      let a = true;
      let res = await API.articleInfo({
        cardid: id,
      }).then((res) => {
        if (res.code == 0) {
          this.$message({
            message: res.msg,
            type: "warning",
          });
          a = false;
        }
      });
      if (a == false) {
        return;
      }
      this.$store.state.loginMessage.cardsalt = id;
      this.jump("details", this.$store.state.loginMessage);
    },
    // 首图展示
    biaoti(obj) {
      if (obj.images.length == 1) {
        if (obj.title.length < 21) {
          return obj.title;
        } else {
          return obj.title.substr(0, 21) + "...";
        }
      }
      if (obj.title.length < 29) {
        return obj.title;
      } else {
        return obj.title.substr(0, 29) + "...";
      }
    },
    async articleCardList() {
      let res = await API.userSupportlist({
        page: this.page,
        pageLine: this.zongyes,
      }).then((res) => {
        if (res.code == 1) {
          let a = res.data.list;
          a.forEach((item) => {
            if (item.images == null) {
              item.images = [];
            }
          });
          this.tieList = a;

          // this.total = res.data.total
          this.$parent.totals(res.data.total);
        }
      });
    },

    pages(num) {
      this.page = num;
      this.articleCardList();
    },
    zongye(num) {
      this.pageLine = num;
      this.articleCardList();
    },
  },
};
</script>

<style scoped lang="scss">
.dainzan {
  width: 100%;
}

.moban_tou {
  height: 46px;

  .fenlei {
    height: 100%;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0 29px 0 37px;
    line-height: 46px;
    position: relative;
    cursor: pointer;

    .sanjiao {
      position: absolute;
      bottom: 0;
      width: 10px;
      height: 5px;
      left: calc(50% - 5px);
      background: transparent;
      border-style: solid;
      border-width: 0 10px 5px 10px;
      border-color: transparent transparent #fff transparent;
    }
  }

  .act {
    position: absolute;
    left: 0;
    top: calc(50% - 16px);
    background: #3eacdd !important;
    font-size: 15px !important;
    color: #ffffff !important;
    height: 70%;
    width: 100%;
    line-height: 32px;
    text-align: center;
  }
}

.biaoge {
  width: 100%;
  background: #ffffff;
  // border: 1px solid #D6DBE3;

  .fenlie {
    .mr20 {
      margin-left: 10px;
      margin-right: 20px;
    }
  }

  .tab_head {
    width: 100%;
    height: 57px;
    display: flex;
    align-items: center;
    background: #e8effa;
    padding: 0 23px 0 22px;
    justify-content: space-between;

    .redian {
      margin-left: 10px;
    }

    .kaung {
      width: 96px;
      height: 100%;
      font-family: PingFangSC-Medium;
      font-size: 15px;
      color: #191a1c;
      letter-spacing: 0;
      font-weight: 500;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .sanjiao {
        position: absolute;
        bottom: 0;
        width: 40px;
        height: 10px;
        left: calc(50% - 20px);
        background: transparent;
        border-style: solid;
        border-width: 0 20px 10px 20px;
        border-color: transparent transparent #e8effa transparent;
      }
    }

    .xuanxian {
      width: 160px;
      justify-content: space-between;

      .one {
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #2b317f;
        letter-spacing: 0;
        font-weight: 500;
      }

      .two {
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #f7593b;
        letter-spacing: 0;
        font-weight: 500;
      }

      .three {
        font-family: PingFangSC-Medium;
        font-size: 15px;
        color: #00a576;
        letter-spacing: 0;
        font-weight: 500;
      }
    }

    .gengduo {
      width: 20px;
      height: 20px;
    }

    .fontFive {
      font-family: PingFangSC-Regular;
      font-size: 15px;
      color: #0653ac;
      letter-spacing: 0;
      font-weight: 400;
      margin-right: 5px;
    }
  }

  // 表身
  .tab_shen {
    padding: 0px 17px 10px 15px;

    .cardd {
      width: 100%;
      border-bottom: 1px solid #d6dbe3;
      padding: 18px 0 17px 0;
      cursor: pointer;
      // &:hover {
      //     cursor: pointer;
      //     background: #CCFFFF;

      // }

      .imgT {
        width: 140px;
        height: 98px;
        margin-right: 21px;
        background: #000;
        position: relative;
      }

      .biaoti {
        margin-bottom: 10px;

        .one {
          font-family: PingFangSC-Regular;
          font-size: 18px;
          letter-spacing: 0;
          font-weight: 400;

          .zi {
            color: #34348f;
          }

          .lv {
            color: #00a576;
          }

          .hong {
            color: #ff5637;
          }
        }

        .two {
          font-family: PingFangSC-Regular;
          font-size: 18px;
          color: #484c51;
          letter-spacing: 0;
          font-weight: 400;
        }

        .three {
          font-family: PingFangSC-Medium;
          font-size: 18px;
          color: #18191a;
          letter-spacing: 0;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 10px;
        }

        .four {
          width: 61px;
          height: 19.7px;
        }
      }

      .nerong {
        width: 100%;
        min-height: 49px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: PingFangSC-Regular;
        font-size: 15px;
        color: #191a1c;
        letter-spacing: 0;
        font-weight: 400;
        display: flex;
        padding: 0 10px;

        .imgT {
          width: 32%;
          height: 150px;
          margin-right: 2%;
          background: #000;
          border-radius: 4px;
          overflow: hidden;
          margin-bottom: 10px;
          position: relative;
        }
      }

      .bofang {
        position: absolute;
        width: 50px;
        height: 50px;
        left: calc(50% - 25px);
        top: calc(50% - 25px);
        border-radius: 50%;
        overflow: hidden;
      }

      .zuozhe {
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #484c51;
        letter-spacing: 0;
        text-align: justify;
        font-weight: 400;

        .up {
          margin-right: 36px;
        }

        .ml23 {
          margin-left: 23px;
        }
      }

      .icon {
        width: 18px;
        height: 18px;
        margin-right: 3px;
      }
    }
  }
}

.zan {
  &:hover {
    color: #c90e0e;
  }
}
</style>