<!-- 发帖列表 -->
<template>
  <div class="_postMessage">
    <div class="moban_tou flex align-items space-between">
      <div class="flex align-items">
        <div
          class="fenlei"
          v-for="(item, index) in liebiao"
          :key="index"
          @click="qiehuan(index)"
        >
          <div>{{ item }}</div>
          <div class="act" v-if="timuNum == index">{{ liebiao[index] }}</div>
        </div>
        <div class="shijian">
          <div class="chagdu">
            <el-date-picker
              @change="onetimer"
              v-model="value1"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="jiange">-</div>
          <div class="chagdu">
            <el-date-picker
              @change="twotimer"
              v-model="value2"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </div>
          <div class="queding">
            <el-button type="primary" @click="shisou">搜索</el-button>
          </div>
        </div>
      </div>
      <div>
        <div
          class="yidu flex align-items cursor_pointer"
          @click="znum = 1"
          v-if="znum != 1"
        >
          <img src="../../../assets/img/xinban/pshan.png" class="icon" alt="" />
          <div class="du">{{ "批量删除" }}</div>
        </div>
        <div class="flex align-items" v-else>
          <div class="yidu flex align-items cursor_pointer du">
            <el-checkbox v-model="checked" @change="qubuxuan">全选</el-checkbox>
          </div>
          <div class="flex align-items cursor_pointer">
            <div class="du" @click="quxiaoa">{{ "取消" }}</div>
          </div>
          <div
            class="yidu flex align-items cursor_pointer"
            style="margin-left: 10px"
          >
            <el-button type="primary" @click="dels"
              >删除({{ heji.length }})</el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="w-full biao">
      <!-- 表头 -->
      <div class="bioatou flex">
        <div v-if="znum != 0" style="width: 13px; height: 13px"></div>
        <!-- <div class="one">{{ "编号" }}</div> -->
        <div class="two">{{ "浏览类型" }}</div>
        <div class="threes">{{ "浏览标题" }}</div>

        <!-- <div class="four text_align">{{ "浏览/评论  " }}</div> -->

        <div class="four text_align">{{ "浏览时间" }}</div>
        <!-- <div class="five">{{ "受理单位" }}</div> -->

        <!-- <div class="two text_align">{{ "是否公开" }}</div> -->

        <!-- <div class="six text_align">{{ "处理状态" }}</div> -->
        <!-- <div class="seven">{{ "操作" }}</div> -->
      </div>
      <div class="biaoshen">
        <!-- 模板 -->
        <div
          class="w-full flex hang align-items"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="flex align-items justify-content">
            <el-checkbox
              v-model="item.zfx"
              v-if="znum != 0"
              @change="xuanyige(index)"
            ></el-checkbox>
          </div>
          <div class="two zixun">
            <!-- v-if="item.type_txt == '咨询'" -->
            <div class="zi">【{{ item.type_txt }}】</div>
            <!-- <div class="lv" v-else-if="item.type_txt == '建议'">
              【{{ "建议" }}】
            </div>
            <div class="hong" v-else>【{{ "投诉" }}】</div> -->
          </div>
          <!-- <div class="one">{{ item.cardsalt }}</div> -->
          <!-- 标题 -->
          <div class="three relative" @mouseover="fuyiru(index)">
            <div class="three" @click="xiangqing(index)">
              {{ xianzhi(item.title) }}
            </div>
          </div>
          <!-- {{ shijian(item.createtime) }} -->
          <div class="four text_align">{{ item.updatetime }}</div>
          <!-- @mouseover="bumenyiru(index)" -->
          <!-- <div class="five relative">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.department_names"
              placement="top-start"
              :disabled="gf(item.department_names)"
            >
              <div>{{ buchang(item.department_names) }}</div>
            </el-tooltip>
          </div> -->

          <!-- <div class="six sta text_align">
            <div class="" v-if="item.status_txt == '待审核'">
              {{ item.status_txt }}
            </div>
            <div class="red" v-else-if="item.status_txt == '待受理'">
              {{ item.status_txt }}
            </div>
            <div class="ju" v-else-if="item.status_txt == '已受理'">
              {{ item.status_txt }}
            </div>
            <div class="green" v-else-if="item.status_txt == '已回复'">
              {{ item.status_txt }}
            </div>
            <div class="hui" v-else-if="item.status_txt == '已办结'">
              {{ item.status_txt }}
            </div>
            <div class="red" v-else-if="item.status_txt == '审核不通过'">
              {{ item.status_txt }}
            </div>
            <div class="red" v-else-if="item.status_txt == '拟办结'">
              {{ "已回复" }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/geren";
import http from "@/api/modules/http.js";
export default {
  data() {
    return {
      loading: null,
      bid: [], //部门的id
      liebiao: ["浏览时间"],
      timuNum: 0,
      page: 1,
      pageLine: 10,
      list: [], //帖子列表
      danqian: 0,
      dialogVisible: false, //评价开关

      yipingjiakh: false,
      xingNum: 1, //星星个数
      xingxing: [
        {
          geshu: [1, 0, 0, 0, 0],
          id: 0,
          name: "11",
          num: 0,
        },
      ],
      yi: 0, //已解决,还是未解决
      banjietixing: false, //办结弹框
      biahao: "",
      funum: 999, //标题全称
      budunum: 999, //部门全称
      searchtime: "", //时间
      value1: "",
      value2: "",
      znum: 0, //批量删除
      heji: [],
    };
  },
  mounted() {
    this.userAcrtile(0);
  },
  methods: {
    onetimer(e) {
      this.value1 = e;
      // console.log(e);
    },
    twotimer(e) {
      this.value2 = e;
    },
    // 时间搜索
    shisou() {
      if (this.value1 == "" || null) {
        if (this.value2 == "" || null) {
          return;
        }
      }
      if (this.value2 == "" || null) {
        if (this.value1 == "" || null) {
          return;
        }
      }
      let a = "";
      let b = "";
      if (this.value1 != null) {
        a = this.value1;
      }
      if (this.value2 != null) {
        b = this.value2;
      }

      // console.log(this.value1 + '~' + this.value2);
      this.searchtime = `${a}~${b}`;
      this.userAcrtile();
    },
    // 确认删除弹框
    dels() {
      if (this.heji.length == 0) {
        return;
      }
      this.$confirm("是否删除选中的消息", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          API.delusmsg({
            id: this.heji.toString(),
          }).then((ress) => {
            if (ress.code == 1) {
              this.page = 1;
              this.userAcrtile();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: ress.msg,
              });
            }
            this.quxiaoa();
          });
        })
        .catch(() => {});
    },
    // 取消
    quxiaoa() {
      this.znum = 0;
      this.heji = [];
      this.checked = false;
      this.list.forEach((item) => {
        item.zfx = false;
      });
      let a = this.list;
      this.list = [];
      this.list = a;
    },
    // 全选
    qubuxuan() {
      if (this.checked == true) {
        let b;
        this.list.forEach((item) => {
          item.zfx = true;
          b = this.heji.indexOf(item.id);
          if (b == -1) {
            this.heji.push(item.id);
          }
        });
      } else {
        this.list.forEach((item) => {
          this.heji.forEach((res, index) => {
            if (item.id == res) {
              item.zfx = false;
              this.heji.splice(index, 1);
            }
          });
        });
      }
      let a = this.list;
      this.list = [];
      this.list = a;
    },
    // 正反选,选一个
    xuanyige(num) {
      // 这里为什么要等于true,因为判断前,组件已将将值改为true了
      if (this.list[num].zfx == true) {
        // this.list[num].zfx = true;
        this.heji.push(this.list[num].id);
      } else {
        // this.list[num].zfx = false;
        let b = this.heji.indexOf(this.list[num].id);
        this.heji.splice(b, 1);
      }
      // let a = this.list;
      // this.list = [];
      // this.list = a;
      this.pdm();
    },
    pdm() {
      let a = true;
      this.list.forEach((item) => {
        if (a == true) {
          if (item.zfx == true) {
            a = true;
          }
        }
        if (item.zfx == false) {
          a = false;
        }
      });
      // a为true代表装载对的数据全部为选中
      if (a == true) {
        this.checked = true;
      } else {
        // 这里只能单纯的把全选取消,不能清空数组
        this.checked = false;
      }
    },
    shijian(timer) {
      var date = new Date(timer * 1000);
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    // 部门移入
    bumenyiru(num) {
      this.budunum = num;
    },
    // 部门移出
    bumenuochu() {
      this.budunum = 999;
    },
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length >= 2) {
        return false;
      } else {
        return true;
      }
    },
    // 部门长度
    buchang(str) {
      let a = str.split(",");
      if (a.length == 1) {
        return a.toString();
      } else {
        return a[0] + "," + "...";
      }
    },
    // 鼠标移入
    fuyiru(num) {
      this.funum = num;
    },
    // 鼠标移出
    fuyichu() {
      this.funum = 999;
    },
    xianzhi(str) {
      if (str.length > 30) {
        return str.slice(0, 29) + "...";
      } else {
        return str;
      }
    },
    // 状态的出现时机
    cxsj(str) {
      if (str == "待审核") {
        return 0;
      }
      return 1;
    },
    // 跳转至为通过审核和隐藏的帖子详情
    wei(id, num) {
      this.$store.state.wt = num;
      this.$store.state.loginMessage.cardsalt = id;
      // this.jump("notpass", this.$store.state.loginMessage);
      // 策略如果不允许评论则跳入detail,现在没有隐藏的帖子
      this.jump("details", this.$store.state.loginMessage);
    },

    async userAcrtile() {
      let res = await API.arthistory({
        logstime: this.searchtime,
        page: this.page,
        pageLine: this.pageLine,
      }).then((res) => {
        if (res.code == 1) {
          let a = res.data.list;
          a.forEach((item) => {
            item["zfx"] = false;
          });
          this.list = a;
          this.$parent.totals(res.data.total);
          this.fanye()
        }
      });
    },
    // 翻页的时候触发
    fanye() {
      // 没有选中时不需要触发
      if (this.heji.length == 0) {
        return;
      }
      // console.log("111----22222");
      // 这里要把已选择的要点亮,不能因为翻页取消掉
      this.list.forEach((item) => {
        this.heji.forEach((res) => {
          if (item.id == res) {
            item.zfx = true;
          }
        });
      });

      let a = true;
      this.list.forEach((item) => {
        if (a == true) {
          if (item.zfx == true) {
            a = true;
          }
        }
        if (item.zfx == false) {
          a = false;
        }
      });
      // a为true代表装载对的数据全部为选中
      if (a == true) {
        this.checked = true;
      } else {
        // 这里只能单纯的把全选取消,不能清空数组
        this.checked = false;
      }
      // 需要重新渲染
      let b = this.list;
      this.list = [];
      this.list = b;
    },
    pages(num) {
      this.page = num;
      this.userAcrtile();
    },
    zongye(num) {
      this.pageLine = num;
      this.userAcrtile();
    },
    // 跳转至详情
    xiangqing(index) {
      this.jumpDel();
      if (this.list[index].kind == 1) {
        this.$store.state.loginMessage.cardsalt = this.list[index].cardsalt;
        this.jump("details", this.$store.state.loginMessage);
      } else {
        this.$store.state.loginMessage.wid = this.list[index].cardsalt;
        this.jump("infosdetail", this.$store.state.loginMessage);
      }
    },
    //切换状态
    qiehuan(num) {
      this.page = 1;
      this.timuNum = num;
      this.userAcrtile(num);
    },
  },
};
</script>

<style scoped lang="scss">
._postMessage {
  width: 100%;
}

.moban_tou {
  height: 46px;

  .fenlei {
    height: 100%;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0 29px 0 37px;
    line-height: 46px;
    position: relative;
    cursor: pointer;
  }
}

.biao {
  .one {
    flex: 1.7;
  }

  .two {
    flex: 0.5;
    text-align: center;
  }
  .threes {
    flex: 3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .three {
    flex: 3;
    white-space: nowrap;
    &:hover {
      color: #f11515;
    }
  }

  .four {
    flex: 1.2;
    // text-align: left;
  }

  .five {
    flex: 1.2;
    text-align: center;
  }

  .six {
    flex: 1.2;
    text-align: center;
  }

  .seven {
    flex: 0.5;
    text-align: center;
  }

  .bioatou {
    width: 100%;
    height: 53px;
    background: #e8effa;
    align-items: center;
    padding: 0 13px 0 13px;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #0653ac;
    letter-spacing: 0;
    font-weight: 400;
  }

  .biaoshen {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;

    .hang:nth-child(even) {
      background: #f6f6f6;
    }

    .hang {
      padding: 0 13px 0 13px;
      min-height: 53px;
      cursor: pointer;
    }
  }

  .zixun {
    .zi {
      color: #2b317f;
    }

    .lv {
      color: #00a576;
    }

    .hong {
      color: #ff5637;
    }
  }

  .sta {
    .red {
      color: #dc203e;
    }

    .ju {
      color: #fa8a2b;
    }

    .green {
      color: #23bc81;
    }

    .hui {
      color: #aea6a0;
    }
  }
}

.act {
  position: absolute;
  left: 0;
  top: calc(50% - 16px);
  background: #3eacdd !important;
  font-size: 15px !important;
  color: #ffffff !important;
  height: 70%;
  width: 100%;
  line-height: 32px;
  text-align: center;
}

:deep(.el-dialog) {
  margin-top: 14vh !important;
  width: 460px !important;
  border-radius: 10px;
}

:deep(.el-dialog__header) {
  padding: 0;
  display: none;
}

:deep(.el-dialog__body) {
  padding: 0px 10px !important;
}

.pingjia {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  // height: 300px;
  position: relative;

  .biaoti {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #27262f;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0 0 20px 0;
  }

  .xing {
    width: 100%;
    justify-content: space-between;
    font-size: 30px;
  }

  .red {
    color: #df2744;
  }

  .wenti {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-align: center;
    margin: 20px 0 20px 0;
  }

  .btn {
    width: 82px;
    height: 34px;
    border-radius: 21.5px;
    border: 2px solid #868d9d;
    margin: 0 10px 0 0;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }

  .actone {
    color: #fff;
    border: 1px solid #f0004a;
    background: #f0004a;
  }

  .ti {
    margin: 20px 0 0 0;

    :deep(.el-button) {
      width: 300px;
      height: 40px;
      background: #2670c5;
      color: #fff;
    }
  }

  .guan {
    position: absolute;
    right: 0px;
    top: -10px;
    font-size: 50px;
    transform: rotate(45deg);
    cursor: pointer;
  }
}

.cz {
  &:hover {
    color: #df2744;
  }
}
.fukuang {
  position: absolute;
  z-index: 20;
  background: #fff;
}
.quanchang {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  background: #fff;
  white-space: nowrap;
  min-width: 100%;
  font-size: 15px;
}
.quanchang:nth-child(even) {
  background: #f6f6f6;
}
.xingzhi {
  align-items: center;
}
.mebumen {
  align-items: center;
  border-bottom: 1px solid #484c51;
}
.shouhu {
  width: 30px;
  height: 30px;
}
.xingzi {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #484c51;
  letter-spacing: 0;
  font-weight: 700;
  width: 140px;
}
.mebumen {
  margin-bottom: 5px;
}
.hexing {
  flex: 1;
  justify-content: space-around;
}
.shijian {
  margin-left: 20px;
  display: flex;
  align-items: center;
  .chagdu {
    width: 140px;
    height: 100%;
    :deep(.el-date-editor.el-input, .el-date-editor.el-input__inner) {
      width: 100%;
    }
  }
  .jiange {
    margin-left: 10px;
    margin-right: 10px;
  }
  .queding {
    width: 100px;
    height: 100%;
    margin-left: 10px;
  }
}
.yidu {
  margin-right: 40px;
}
.icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
</style>
<style lang='scss'>
</style>