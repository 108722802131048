// 个人简介
import request from '../request'

export default class Geren {

    /**
  * 用户信息
  * @param {*} params
  * @returns
  */
    static user() {
        return request.get('/api/user/index')
    }
    /**
 * 个人信息
 * @param {*} params
 * @returns
 */
    static userHomepage() {
        return request.get('/api/v1.usercontrol/userHomepage')
    }
    /**
* 我的收藏
kind	enum	是	类型:0=提问,1=投诉,2=收藏
type	enum	是	类型:0=上线,1=草稿
page	enum	是	页码，如 1
pageLine	enum	是	一页个数,如 10
* @param {*} params
* @returns
*/
    static artileContentContor(data) {
        return request.post('/api/v1.usercontrol/artileContentContor', data)
    }
    /**
* 关注
* @param {*} params
* @returns
*/
    static useFollowlike(params) {
        return request.get('/api/v1.messagecenter/useFollowlike', {
            params
        })
    }
    /**
* 评论回复
* @param {*} params
* @returns
*/
    static userReplylist(params) {
        return request.get('/api/v1.usercontrol/userReplylist', {
            params
        })
    }
    /**
* 收藏点赞
* @param {*} params
* @returns
*/
    static artileCollect(data) {
        return request.post('/api/v1.usercontrol/artileCollect', data)
    }
    /**
* 发帖列表
* @param {*} params
* @returns
*/
    static userAcrtile(params) {
        return request.get('/api/v1.usercontrol/userAcrtile', {
            params
        })
    }
    /**
* 草稿箱
* @param {*} params
* @returns
*/
    static userDraft(params) {
        return request.get('/api/v1.usercontrol/userDraft', {
            params
        })
    }
    /**
* 删除帖子
* @param {*} params
* @returns
*/
    static articleDel(data) {
        return request.post('/api/v1.usercontrol/articleDel', data)
    }
    /**
* 催办帖子
* @param {*} params
* @returns
*/
static articlecuiban(params) {
    return request.get('/api/v1/Usercontrol/arturgent', {
        params
    })
}

    
    /**
     * 
* 我的点赞
* @param {*} params
* @returns
*/
    static userSupportlist(params) {
        return request.get('/api/v1.usercontrol/userSupportlist', {
            params
        })
    }
    /**
* 收藏点赞
* @param {*} params
* @returns
*/
    static artileCollect(data) {
        return request.post('/api/v1.usercontrol/artileCollect', data)
    }

    /**
* 办结帖子
* @param {*} params
* @returns
*/
    static completArt(params) {
        return request.get('/api/v1.usercontrol/completArt', {
            params
        })
    }
    /**
* 公开或不公开
* @param {*} params
* @returns
*/
    static articleClose(data) {
        return request.post('/api/v1.usercontrol/articleClose', data)
    }
    /**
* 帖子详情
* @param {*} params 
* @returns 
*/
    static articleInfo(params) {
        return request.get("/api/v1.articlecurrency/articleInfo", {
            params
        })
    }
    /**
* 个人评论申请删除
* @param {*} params 
* @returns 
*/
    static delcomment(params) {
        return request.get("/api/v1/Usercontrol/delcomment", {
            params
        })
    }
    /**
* 浏览记录
* @param {*} params 
* @returns 
*/
    static arthistory(params) {
        return request.get("/api/v1/Usercontrol/usrhistroy", {
            params
        })
    }
    /**
* 删除历史记录 
* @param {*} params 
* @returns 
*/
    static delusmsg(params) {
        return request.get("/api/v1/Usercontrol/delusmsg", {
            params
        })
    }
}