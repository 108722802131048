<!-- 发帖列表 -->
<template>
  <div class="_postMessage">
    <div class="moban_tou flex align-items">
      <div
        class="fenlei"
        v-for="(item, index) in liebiao"
        :key="index"
        @click="qiehuan(index)"
      >
        {{ item }}
        <div class="sanjiao"></div>
        <div class="act" v-if="timuNum == index">{{ liebiao[index] }}</div>
      </div>
    </div>
    <!-- 表格 -->
    <div class="w-full biao">
      <!-- 表头 -->
      <div class="bioatou flex">
        <div class="one">{{ "编号" }}</div>
        <div class="threes">{{ "标题" }}</div>

        <div class="four text_align">{{ "浏览/评论  " }}</div>

        <div class="two">{{ "诉求类型" }}</div>
        <div class="four text_align">{{ "诉求时间" }}</div>
        <div class="five">{{ "受理单位" }}</div>

        <div class="two text_align">{{ "是否公开" }}</div>

        <div class="six text_align">{{ "处理状态" }}</div>
        <div class="seven">{{ "操作" }}</div>
      </div>
      <div class="biaoshen">
        <!-- 模板 -->
        <div
          class="w-full flex hang align-items"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="one">{{ item.cardsalt }}</div>
          <!-- 标题 -->
          <div class="three relative" @mouseover="fuyiru(index)">
            <div class="fukuang" v-if="funum == index" @mouseout="fuyichu">
              <div
                v-if="item.status_txt == '审核不通过'"
                @click.stop="wei(item.cardsalt, '1')"
              >
                {{ item.title }}
              </div>
              <div
                v-else-if="item.is_hide != 0"
                @click.stop="wei(item.cardsalt, '0')"
              >
                {{ item.title }}
              </div>
              <div
                v-else
                @click="
                  xiangqing(item.status_txt, item.cardsalt, item.examreason)
                "
              >
                {{ item.title }}
              </div>
            </div>
            <div
              v-if="item.status_txt == '审核不通过'"
              class="three"
              @click.stop="wei(item.cardsalt, '1')"
            >
              {{ xianzhi(item.title) }}
            </div>
            <div
              v-else-if="item.is_hide != 0"
              class="three"
              @click.stop="wei(item.cardsalt, '0')"
            >
              {{ xianzhi(item.title) }}
            </div>
            <div
              v-else
              class="three"
              @click="
                xiangqing(item.status_txt, item.cardsalt, item.examreason)
              "
            >
              {{ xianzhi(item.title) }}
            </div>
          </div>
          <div class="four text_align">
            <span>{{ item.views }}</span
            >/<span>{{ item.comments }}</span>
          </div>
          <div class="two zixun">
            <div class="zi" v-if="item.type == '咨询'">【{{ "咨询" }}】</div>
            <div class="lv" v-else-if="item.type == '建议'">
              【{{ "建议" }}】
            </div>
            <div class="hong" v-else>【{{ "投诉" }}】</div>
          </div>
          <div class="four text_align">{{ item.articletime.slice(0, 10) }}</div>
          <!-- @mouseover="bumenyiru(index)" -->
          <div class="five relative">
            <el-tooltip
              class="item"
              effect="dark"
              :content="item.unit_name"
              placement="top-start"
              :disabled="gf(item.unit_name)"
            >
              <div>{{ buchang(item.unit_name) }}</div>
            </el-tooltip>
            <!-- <div
              @mouseout="bumenuochu"
              v-if="budunum == index"
              class="quanchang"
            >
              {{ item.unit_name }}
            </div> -->
          </div>

          <div class="two text_align">
            <!-- {{ item.is_hide == 0 ? "否" : "是" }} -->
            {{ item.is_open == 0 ? "不公开" : "公开" }}
          </div>

          <div class="six sta text_align">
            <div class="" v-if="item.status_txt == '待审核'">
              {{ item.status_txt }}
            </div>
            <div class="red" v-else-if="item.status_txt == '待受理'">
              {{ item.status_txt }}
            </div>
            <div class="ju" v-else-if="item.status_txt == '已受理'">
              {{ item.status_txt }}
            </div>
            <div class="green" v-else-if="item.status_txt == '已回复'">
              {{ item.status_txt }}
            </div>
            <div class="hui" v-else-if="item.status_txt == '已办结'">
              {{ item.status_txt }}
            </div>
            <div class="red" v-else-if="item.status_txt == '审核不通过'">
              {{ item.status_txt }}
            </div>
            <div class="red" v-else-if="item.status_txt == '拟办结'">
              {{ "已回复" }}
            </div>
          </div>
          <!-- @click.stop="jiehsu(item.cardsalt)" -->
          <div class="seven">
            <div class="w-full" v-if="item.status_txt == '已回复'">
              <div class="cz" @click.stop="shanchu(item.cardsalt)">
                {{ "删除" }}
              </div>
              <div
                class="cz"
                v-if="cxsj(item.status_txt) == 1"
                @click="articleClose(item.is_open, item.cardsalt)"
              >
                {{ item.is_open == 0 ? "公开" : "不公开" }}
              </div>
              <!-- <div class="cz">{{ '办结' }}</div> -->
            </div>
            <div class="w-full" v-else-if="item.status_txt == '已办结'">
              <div class="cz" @click.stop="shanchu(item.cardsalt)">
                {{ "删除" }}
              </div>
              <div
                class="cz"
                v-if="cxsj(item.status_txt) == 1"
                @click="articleClose(item.is_open, item.cardsalt, index)"
              >
                {{ item.is_open == 0 ? "公开" : "不公开" }}
              </div>
              <div
                class="cz"
                @click.stop="pingjia(index, item.cardsalt, index)"
                v-if="item.is_eval == 0"
              >
                {{ "评价" }}
              </div>
              <div v-else @click="yiping(item.sevaluate)">{{ "已评价" }}</div>
            </div>
            <div class="w-full" v-else-if="item.status_txt == '审核不通过'">
              <div class="cz" @click.stop="shanchu(item.cardsalt)">
                {{ "删除" }}
              </div>
              <div class="cz" @click.stop="yuanyin(item.examreason)">
                {{ "原因" }}
              </div>
            </div>
            <div class="w-full"  v-else-if="item.status_txt == '待受理' || item.status_txt == '已受理'">
              <div class="cz" @click.stop="shanchu(item.cardsalt)">
                {{ "删除" }}
              </div>
              <div
                class="cz"
                @click="articleClose(item.is_open, item.cardsalt, index)"
                v-if="cxsj(item.status_txt) == 1"
              >
                {{ item.is_open == 0 ? "公开" : "不公开" }}
              </div>
              <div class="cz" @click.stop="cuiban(item.cardsalt)">
                {{ "催办" }}
              </div>
            </div>
            <div class="w-full" v-else>
              <div class="cz" @click.stop="shanchu(item.cardsalt)">
                {{ "删除" }}
              </div>
              <div
                class="cz"
                @click="articleClose(item.is_open, item.cardsalt, index)"
                v-if="cxsj(item.status_txt) == 1"
              >
                {{ item.is_open == 0 ? "公开" : "不公开" }}
              </div>
            </div>
          </div>
          <!-- <div class="seven" v-else>
            <div class="cz" @click.stop="shanchu(item.cardsalt)">
              {{ "删除" }}
            </div>
            <div class="cz" @click.stop="yuanyin(item.hide_reason)">
              {{ "原因" }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <!-- 评价提醒 -->
    <el-dialog :visible.sync="dialogVisible">
      <div class="pingjia">
        <div class="biaoti">{{ "请对我们的服务进行评价" }}</div>
        <div class="xing">
          <div
            v-for="(item, index) in xingxing"
            :key="index"
            class="cursor_pointer xingzhi"
          >
            <div class="flex mebumen">
              <div class="xingzi">{{ item.name }}:</div>
              <div
                class="flex hexing"
                v-for="(res, num) in item.geshu"
                :key="num"
                @click="geshu(index, num)"
              >
                <img
                  src="../../../assets/img/xinban/huan.png"
                  v-if="res == 1"
                  alt=""
                  class="shouhu"
                />
                <img
                  class="shouhu"
                  src="../../../assets/img/xinban/hui.png"
                  v-else
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="wenti">{{ "本次问题" }}</div>
        <div class="flex justify-content">
          <div class="btn" :class="{ actone: yi == 1 }" @click="yi = 1">
            已解决
          </div>
          <div class="btn" :class="{ actone: yi == 0 }" @click="yi = 0">
            未解决
          </div>
        </div>
        <div class="flex justify-content ti">
          <el-button round @click.stop="tijaio">{{ "提交" }}</el-button>
          <!-- <el-button :type="'info'" round @click.stop="(dialogVisible = false)">{{ '取消' }}</el-button> -->
        </div>
        <div class="guan" @click.stop="dialogVisible = false">{{ "+" }}</div>
      </div>
    </el-dialog>
    <!-- 办结提醒 -->
    <!-- 已评价 -->
    <el-dialog :visible.sync="yipingjiakh">
      <div class="pingjia">
        <div class="biaoti">{{ "您的评价是:" }}</div>
        <div class="xing">
          <div
            v-for="(item, index) in xingxing"
            :key="index"
            class="cursor_pointer xingzhi"
          >
            <div class="flex mebumen">
              <div class="xingzi">{{ item.name }}:</div>
              <div
                class="flex hexing"
                v-for="(res, num) in item.geshu"
                :key="num"
              >
                <img
                  src="../../../assets/img/xinban/huan.png"
                  v-if="res == 1"
                  alt=""
                  class="shouhu"
                />
                <img
                  class="shouhu"
                  src="../../../assets/img/xinban/hui.png"
                  v-else
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="wenti">{{ "本次问题" }}</div>
        <div class="flex justify-content">
          <div class="btn" :class="{ actone: yi == 1 }">已解决</div>
          <div class="btn" :class="{ actone: yi == 0 }">未解决</div>
        </div>
        <div class="flex justify-content ti">
          <el-button round @click.stop="yipingjiakh = false">{{
            "确定"
          }}</el-button>
          <!-- <el-button :type="'info'" round @click.stop="(dialogVisible = false)">{{ '取消' }}</el-button> -->
        </div>
        <div class="guan" @click.stop="yipingjiakh = false">{{ "+" }}</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/modules/geren";
import http from "@/api/modules/http.js";
export default {
  data() {
    return {
      loading: null,
      bid: [], //部门的id
      liebiao: ["全部", "待受理", "已受理", "已回复", "已办结"],
      timuNum: 0,
      page: 1,
      pageLine: 10,
      list: [], //帖子列表
      danqian: 0,
      dialogVisible: false, //评价开关

      yipingjiakh: false,
      xingNum: 1, //星星个数
      xingxing: [
        {
          geshu: [1, 0, 0, 0, 0],
          id: 0,
          name: "11",
          num: 0,
        },
      ],
      yi: 0, //已解决,还是未解决
      banjietixing: false, //办结弹框
      biahao: "",
      funum: 999, //标题全称
      budunum: 999, //部门全称
    };
  },
  mounted() {
    this.userAcrtile(0);
  },
  methods: {
    // 已评价
    yiping(obj) {
      this.xingxing = [];
      this.yi = obj[0].is_solve;
      obj.forEach((item, index) => {
        let b = [1, 0, 0, 0, 0];
        let a = item.star;
        if (a == 1) {
          b = [1, 0, 0, 0, 0];
        }
        if (a == 2) {
          b = [1, 1, 0, 0, 0];
        }
        if (a == 3) {
          b = [1, 1, 1, 0, 0];
        }
        if (a == 4) {
          b = [1, 1, 1, 1, 0];
        }
        if (a == 5) {
          b = [1, 1, 1, 1, 1];
        }
        this.xingxing.push({
          geshu: b,
          id: "",
          name: item.department_name,
          num: 0,
        });
      });
      this.yipingjiakh = true;
    },
    // 部门移入
    bumenyiru(num) {
      this.budunum = num;
    },
    // 部门移出
    bumenuochu() {
      this.budunum = 999;
    },
    // 判断是否有top提示
    gf(str) {
      let a = str.split(",");
      if (a.length >= 2) {
        return false;
      } else {
        return true;
      }
    },
    // 部门长度
    buchang(str) {
      let a = str.split(",");
      if (a.length == 1) {
        return a.toString();
      } else {
        return a[0] + "," + "...";
      }
    },
    // 鼠标移入
    fuyiru(num) {
      this.funum = num;
    },
    // 鼠标移出
    fuyichu() {
      this.funum = 999;
    },
    xianzhi(str) {
      if (str.length > 20) {
        return str.slice(0, 19) + "...";
      } else {
        return str;
      }
    },
    cuiban (id){
      API.articlecuiban({
        cardsalt: id,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
    },
    // 公开不公开
    async articleClose(num, id, index) {
      if (num == 1) {
        this.$prompt('请输入不公开理由', '是否不公开该贴', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^.+$/,
          inputErrorMessage: '请输入不公开理由'
        }).then(({ value }) => {
          // this.$message({
          //   type: 'success',
          //   message: '你的邮箱是: ' + value
          // });
         API.articleClose({
               reason:value,
              cardid: id,
              type: 0,
            }).then((res) => {
              if (res.code == 1) {
                // this.list[index].is_open = 0;
                // this.userAcrtile(this.danqian);
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消输入'
          // });       
        });
       
      } else {
        this.$prompt('请输入公开理由', '公开该贴将由管理员审核,是否将该贴公开?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^.+$/,
          inputErrorMessage: '请输入公开理由'
        }).then(({ value }) => {
          // this.$message({
          //   type: 'success',
          //   message: '你的邮箱是: ' + value
          // });
          API.articleClose({
            reason:value,
              cardid: id,
              kind: 1,
            }).then((res) => {
              if (res.code == 1) {
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.msg,
                  type: "warning",
                });
              }
            });
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消输入'
          // });       
        });
      
      }
    },
    // 状态的出现时机
    cxsj(str) {
      if (str == "待审核") {
        return 0;
      }
      return 1;
    },
    // 跳转至为通过审核和隐藏的帖子详情
    wei(id, num) {
      this.$store.state.wt = num;
      this.$store.state.loginMessage.cardsalt = id;
      // this.jump("notpass", this.$store.state.loginMessage);
      // 策略如果不允许评论则跳入detail,现在没有隐藏的帖子
      this.jump("details", this.$store.state.loginMessage);
    },
    // 删除接口
    async shanchu(id) {
      this.$prompt('请输入删除理由', '删除该贴将由管理员审核,是否删除?', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^.+$/,
          inputErrorMessage: '请输入删除理由'
        }).then(({ value }) => {
          // this.$message({
          //   type: 'success',
          //   message: '你的邮箱是: ' + value
          // });
          API.articleDel({
            reason:value,
            cardid: id,
          }).then((res) => {
            if (res.code == 1) {
              this.$message({
                message: res.msg,
                type: "success",
              });
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        }).catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '取消输入'
          // });       
        });
    },
    async tijaio() {
      this.loading = this.$loading({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let a = [];
      this.xingxing.forEach((item) => {
        a.push({
          department_id: item.id,
          star: item.num,
        });
      });
      let res = await http
        .articleEvl({
          cardid: this.bianhao,
          content: a,
          solve: this.yi,
        })
        .then((res) => {
          if (res.code == 1) {
            this.xingNum = 1;
            this.yi = 0;
            setTimeout(() => {
              this.userAcrtile(this.danqian);
              this.$message({
                message: "评价成功!",
                type: "success",
              });
              this.dialogVisible = false;
              this.loading.close();
            }, 3000);
          } else {
            this.loading.close();
            this.$message({
              message: res.msg,
              type: "warning",
            });
          }
        })
        .finally((suo) => {
          // 都执行
          setTimeout(() => {
            this.loading.close();
            this.dialogVisible = false;
          }, 3000);
        });
    },
    // 帖子办结
    jiehsu(id) {
      this.$confirm("是否确定该贴已经办结", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await API.completArt({
            cardid: id,
          }).then((obj) => {
            if (obj.code == 1) {
              this.userAcrtile(this.danqian);
            } else {
              this.$message({
                message: res.msg,
                type: "warning",
              });
            }
          });
        })
        .catch(() => {});
    },
    // 星星评价
    geshu(index, num) {
      this.xingxing[index].num = num + 1;
      let a = [0, 0, 0, 0, 0];
      a.forEach((item, index) => {
        if (num >= index) {
          a[index] = 1;
        }
      });
      this.xingxing[index].geshu = [];
      this.xingxing[index].geshu = a;
    },
    // 评价
    pingjia(num, id) {
      this.bianhao = id;
      this.bid = this.list[num].depart;
      let a = this.list[num].unit_name.split(",");
      this.xingxing = [];
      this.bid.forEach((item, index) => {
        this.xingxing.push({
          geshu: [1, 0, 0, 0, 0],
          id: item,
          name: a[index],
          num: 1,
        });
      });
      // console.log(this.xingxing, "sjhsd");
      this.dialogVisible = true;
    },
    async userAcrtile(num) {
      this.danqian = num;
      let res = await API.userAcrtile({
        // useid: localStorage.getItem('uid'),
        kind: num,
        page: this.page,
        pageLine: this.pageLine,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data.list;
          this.$parent.totals(res.data.total);
        }
      });
    },
    pages(num) {
      this.page = num;
      this.userAcrtile(this.danqian);
    },
    zongye(num) {
      this.pageLine = num;
      this.userAcrtile(this.danqian);
    },
    // 不通过的原因
    yuanyin(center) {
      this.$alert(center, "该贴尚未通过审核,未通过原因为:", {
        confirmButtonText: "确定",
        // callback: action => {

        // }
      });
    },
    // 跳转至详情
    xiangqing(name, id, center) {
      if (name == "待审核") {
        this.$store.state.loginMessage.cardsalt = id;
        this.jump("details", this.$store.state.loginMessage);
      } else if (name == "审核不通过") {
        let a = center;
        this.$alert(center, "该贴尚未通过审核,未通过原因为:", {
          customClass: "llll",
          confirmButtonText: "确定",

          // callback: action => {

          // }
        });
      } else {
        this.$store.state.loginMessage.cardsalt = id;
        this.jump("details", this.$store.state.loginMessage);
      }
    },
    //切换状态
    qiehuan(num) {
      this.page = 1;
      this.timuNum = num;
      this.userAcrtile(num);
    },
  },
};
</script>

<style scoped lang="scss">
._postMessage {
  width: 100%;
}

.moban_tou {
  height: 46px;

  .fenlei {
    height: 100%;
    text-align: center;
    font-family: PingFangSC-Medium;
    font-size: 15px;
    color: #36383a;
    letter-spacing: 0;
    font-weight: 500;
    padding: 0 29px 0 37px;
    line-height: 46px;
    position: relative;
    cursor: pointer;

    .sanjiao {
      position: absolute;
      bottom: 0;
      width: 10px;
      height: 5px;
      left: calc(50% - 5px);
      background: transparent;
      border-style: solid;
      border-width: 0 10px 5px 10px;
      border-color: transparent transparent #fff transparent;
    }
  }
}

.biao {
  .one {
    flex: 1.7;
  }

  .two {
    flex: 1.2;
    text-align: center;
  }
  .threes {
    flex: 3;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .three {
    flex: 3;
    white-space: nowrap;
    &:hover {
      color: #f11515;
    }
  }

  .four {
    flex: 1.2;
    // text-align: left;
  }

  .five {
    flex: 1.2;
    text-align: center;
  }

  .six {
    flex: 1.2;
    text-align: center;
  }

  .seven {
    flex: 0.5;
    text-align: center;
  }

  .bioatou {
    width: 100%;
    height: 53px;
    background: #e8effa;
    align-items: center;
    padding: 0 13px 0 13px;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    color: #0653ac;
    letter-spacing: 0;
    font-weight: 400;
  }

  .biaoshen {
    font-family: PingFangSC-Regular;
    font-size: 13px;
    color: #191a1c;
    letter-spacing: 0;
    font-weight: 400;

    .hang:nth-child(even) {
      background: #f6f6f6;
    }

    .hang {
      padding: 0 13px 0 13px;
      min-height: 53px;
      cursor: pointer;
    }
  }

  .zixun {
    .zi {
      color: #2b317f;
    }

    .lv {
      color: #00a576;
    }

    .hong {
      color: #ff5637;
    }
  }

  .sta {
    .red {
      color: #dc203e;
    }

    .ju {
      color: #fa8a2b;
    }

    .green {
      color: #23bc81;
    }

    .hui {
      color: #aea6a0;
    }
  }
}

.act {
  position: absolute;
  left: 0;
  top: calc(50% - 16px);
  background: #3eacdd !important;
  font-size: 15px !important;
  color: #ffffff !important;
  height: 70%;
  width: 100%;
  line-height: 32px;
  text-align: center;
}

:deep(.el-dialog) {
  margin-top: 14vh !important;
  width: 460px !important;
  border-radius: 10px;
}

:deep(.el-dialog__header) {
  padding: 0;
  display: none;
}

:deep(.el-dialog__body) {
  padding: 0px 10px !important;
}

.pingjia {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  // height: 300px;
  position: relative;

  .biaoti {
    font-family: PingFangSC-Medium;
    font-size: 18px;
    color: #27262f;
    letter-spacing: 0;
    font-weight: 500;
    margin: 0 0 20px 0;
  }

  .xing {
    width: 100%;
    justify-content: space-between;
    font-size: 30px;
  }

  .red {
    color: #df2744;
  }

  .wenti {
    font-family: PingFangSC-Medium;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    text-align: center;
    margin: 20px 0 20px 0;
  }

  .btn {
    width: 82px;
    height: 34px;
    border-radius: 21.5px;
    border: 2px solid #868d9d;
    margin: 0 10px 0 0;
    text-align: center;
    line-height: 32px;
    cursor: pointer;
  }

  .actone {
    color: #fff;
    border: 1px solid #f0004a;
    background: #f0004a;
  }

  .ti {
    margin: 20px 0 0 0;

    :deep(.el-button) {
      width: 300px;
      height: 40px;
      background: #2670c5;
      color: #fff;
    }
  }

  .guan {
    position: absolute;
    right: 0px;
    top: -10px;
    font-size: 50px;
    transform: rotate(45deg);
    cursor: pointer;
  }
}

.cz {
  &:hover {
    color: #df2744;
  }
}
.fukuang {
  position: absolute;
  z-index: 20;
  background: #fff;
}
.quanchang {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 20;
  background: #fff;
  white-space: nowrap;
  min-width: 100%;
  font-size: 15px;
}
.quanchang:nth-child(even) {
  background: #f6f6f6;
}
.xingzhi {
  align-items: center;
}
.mebumen {
  align-items: center;
  border-bottom: 1px solid #484c51;
}
.shouhu {
  width: 30px;
  height: 30px;
}
.xingzi {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #484c51;
  letter-spacing: 0;
  font-weight: 700;
  width: 140px;
}
.mebumen {
  margin-bottom: 5px;
}
.hexing {
  flex: 1;
  justify-content: space-around;
}
</style>
<style lang='scss'>
</style>